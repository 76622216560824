<template>
  <div>
    <MiniCard
      v-if="club.title === 'Gitano Jungle'"
      title="Gitano Cake & Champagne packages"
      text="Toast to Life's Special Moments!"
      link="/tulum-shorts/gitano-tulum-packages"
      image="https://imgix.cosmicjs.com/c6a1aad0-f8bb-11ed-9363-e19d07f83187-Gitano-Cake-Packages.jpg"
    ></MiniCard>
    <MiniCard
      v-if="club.title === 'Gitano Jungle'"
      title="Gitano Tulum provides a unique ambience"
      text=" Music & Food blends in"
      link="/tulum-shorts/cosmic-video"
      image="https://imgix.cosmicjs.com/7c6e8ca0-2cd5-11ee-9b7c-69a4789b503a-Preview-Image-Gitano.jpg"
    ></MiniCard>
    <MiniCard
      v-if="club.title === 'Bonbonniere'"
      title="Bonbonniere"
      text="Table Prices"
      link="/tulum-shorts/bonbonniere-tulum-table-prices"
      image="https://imgix.cosmicjs.com/862b6210-f4ed-11ed-bb44-790a83f99a24-1684353648092.jpg"
    ></MiniCard>
    <MiniCard
      v-if="club.title === 'Bonbonniere'"
      title="Ancestral Soul"
      text="Ancestral Soul awakens hearts."
      link="/brands/ancestral-soul-tulum"
      image="https://imgix.cosmicjs.com/e930f090-cf86-11ee-9ce5-59949019255e-logo-ancestral-soul-tulum.png"
    ></MiniCard>
    <MiniCard
      v-if="club.title === 'Taboo Tulum'"
      title="TABOO  DAY EVENTS"
      text="Live Shows and Happenings at Taboo"
      link="/article/taboo-tulum-day-events"
      image="https://imgix.cosmicjs.com/a23dd150-61b1-11ee-bacf-0595d1e10c0d-image.png"
    ></MiniCard>
    <MiniCard
      v-if="club.title === 'Bagatelle'"
      title="Bagatelle: Dinner & Party"
      text="Dinner Meets Vibrant Party"
      link="/article/bagatelle-tulum-dinner-party"
      image="https://imgix.cosmicjs.com/74d31910-1778-11ee-94ef-b32c6d5fe768-Dinner-Sessions-card-image.jpg"
    ></MiniCard>

    <MiniCard
      v-if="club.title !== 'Vagalume'"
      title="Vagalume Tulum"
      text="Dancing Nights"
      link="/tulum-clubs/vagalume-tulum"
      image="https://imgix.cosmicjs.com/b3123650-6e23-11ee-8960-f1f803b242c2-Vagalume1.jpg"
    ></MiniCard>
    <MiniCard
      v-if="club.title !== 'Bonbonniere'"
      title="Bonbonniere"
      text="Follow The Red Light"
      link="/tulum-clubs/bonbonniere-tulum"
      image="https://imgix.cosmicjs.com/862b6210-f4ed-11ed-bb44-790a83f99a24-1684353648092.jpg"
    ></MiniCard>
    <MiniCard
      v-if="club.title !== 'Bagatelle'"
      title="Bagatelle Tulum"
      text="Festive Dinners"
      link="/tulum-clubs/bagatelle-tulum"
      image="https://imgix.cosmicjs.com/87cfa860-6ea0-11ee-8960-f1f803b242c2-Bagatelle-4.jpg"
    ></MiniCard>

    <MiniCard
      title="Top Beach Clubs"
      text="Best Clubs To Spend Your Day"
      link="/tulum-day-beach-clubs"
      image="https://imgix.cosmicjs.com/fa273ba0-e75e-11ec-9447-f98173199613-IMAGE-2022-06-08-141242.jpg"
    ></MiniCard>


  </div>
</template>
<script>

import  MiniCard from "@/components/Cards/MiniCard";
export default {
  name: 'ClubRecommendedArticles',
  props: ['club'],
  components: { MiniCard }
};
</script>
