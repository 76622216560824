export default Object.freeze({
  'bonbonniere-tulum': {
    name: 'Bonbonniere',
    logo:
      'https://imgix.cosmicjs.com/ddc833b0-1568-11ec-9133-6f7b9313cf59-bonbonniere.png?h=200&w=200',
    smallLogo:
      'https://imgix.cosmicjs.com/16114590-d4d4-11ee-9ce5-59949019255e-logo-bonbonniere-tulum.png',
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=fe0304,FA8072&duotone-alpha=100&px=10&auto=format`,
    secondaryColor: '#817d7d',
    color: '#4a1f1f',
    video: {
      title: '🧨 Red Nights ',
      youtube: 'true',
      content:
        '<strong>Step into the Luxurious Rhythm</strong>: Where Nightlife Dreams Come Alive. Experience the epitome of elegance and dance to the beats of the extraordinary intense caribbean nights at <strong>Bonbonniere Tulum.</strong>',
      id: 'nQfJfuTvww0',
      videoUrl: 'https://www.youtube.com/embed/nQfJfuTvww0',
    },
  },
  'mia-beach-club-tulum': {
    name: 'Mia Tulum',
    logo:
      'https://imgix.cosmicjs.com/988e3350-d4d6-11ee-9ce5-59949019255e-logo-mia-beach-club-tulum.png',
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=dbdf0c,FA8072&duotone-alpha=100&px=11&auto=format`,
    color: '#f95959',
    secondaryColor:  '#f95959',
    video: {
      title: 'MIA - ✅ Must Go If ',
      content:
        '<p>- You seek a techno-house party with a vibrant atmosphere .  Mia Tulum has a great selection and variety of djs. <br><strong>NO DRESS CODE !</strong></p>',
      youtube: false,
      poster:
        'https://imgix.cosmicjs.com/073b52b0-483c-11ee-89ab-17371fc03105-image.png',
      videoUrl:
        'https://imgix.cosmicjs.com/f9b8f660-24f5-11ee-982a-ed7f706ddfc5-Mia-Tulum-video-night.MP4',
    },
  },
  'bagatelle-tulum': {
    name: 'Bagatelle Tulum',
    logo:
      'https://imgix.cosmicjs.com/3c946670-d5a6-11ee-9ce5-59949019255e-logo-bagatelle-tulum.png',
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=385072,385072&duotone-alpha=100&auto=format`,
    color: 'rgb(61 142 185)',
    secondaryColor: '#c7c6ca',
    articles: ['bagatelle-tulum-dinner-party'],
    video: {
      title: '🏆 PRO TIP',
      poster:
        'https://imgix.cosmicjs.com/d6d7ab40-483c-11ee-89ab-17371fc03105-image.png',
      youtube: false,
      caption: 'ARODES - Red District Nights',
      content:
        '<strong>Bagatelle\'s</strong> central location in the beach zone not only makes it <strong>the perfect spot for a festive dinner</strong>, but also provides easy access to other popular venues such as <a href="/tulum-clubs/bonbonniere-tulum">Bonbonniere</a>, <a href="/tulum-clubs/vagalume-tulum">Vagalume</a>, and  <a href="/tulum-clubs/mia-beach-club-tulum">Mia</a>, allowing you to continue the party at your next chosen destination in just a breeze.',
      videoUrl:
        'https://imgix.cosmicjs.com/1bb8f9b0-2684-11ee-b83c-8d84c532f1e4-Bagatelle-Tulum-Club-Video-mp4.MP4',
    },
  },
  'vagalume-tulum': {
    name: 'Vagalume',
    color: '#8f684d',
    secondaryColor: '#292016',
    logo:
      'https://imgix.cosmicjs.com/55d7ade0-07d5-11ed-b7be-d956591ad437-vagalume-logo.png?h=60',
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=orange,FA8072&duotone-alpha=100&px=10`,
    video: {
      title: '📺 IN ACTION: Summerians',
      youtube: 'true',
      content: '<hr> <p> <strong>Vagalume</strong> offers a collection of 💥 explosive <strong>show brands</strong> known for their <strong>high-quality production</strong> and unmistakable Ibiza influence. Each show at Vagalume possesses its own distinct vibe. From the energetic <strong> Sinner sessions</strong>,  to the mesmerizing <strong>Summerians.</strong> ',
      id: 'x1ePVYFl08I?t=390',
      videoUrl: 'https://www.youtube.com/embed/x1ePVYFl08I?t=390',
    },
  },
  'confessions-tulum': {
    name: 'Confessions',
    color: '#704acf',
    secondaryColor: '#c351c8',
    logo:
      'https://imgix.cosmicjs.com/eae741c0-d4d9-11ee-9ce5-59949019255e-logo-confessions-tulum.png',
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=80009f,black&duotone-alpha=100`,
    video: {
      title: '🌴 AFTER-DINNER PARTY',
      content:
        'Feel alive through the <strong>neon lights of this exotic jungle bar and lounge</strong>. Unveil the Exotic Vibes at Confessions Tulum.',
      poster:
        'https://imgix.cosmicjs.com/9fd31d10-f0c1-11ec-8fb8-5d396858ac9b-Picture2.jpg',
      youtube: false,
      videoUrl:
        'https://imgix.cosmicjs.com/d48ee460-eb78-11ec-bb77-f7704be6fe97-WhatsApp-Video-2022-06-13-at-12.22.16-PM.mp4',
    },
  },
  'papaya-playa': {
    name: 'Papaya Playa',
    color: 'rgb(115 66 82)',
    secondaryColor: '#313742',
    logo:
      'https://imgix.cosmicjs.com/a8886e20-d4d5-11ee-9ce5-59949019255e-logo-papaya-playa.png',
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=8ba99f,black&duotone-alpha=100&px=10`,
    video: {
      title: 'BLOND:ISH LIVE SET: 2023',
      youtube: true,
      content:
        '<strong>Papaya Playa Project is a one-stop destination for music lovers</strong>, a unique blend of hotel, restaurant, and beach club. Hosting an <strong>impressive mix of world-renowned and uprising DJs</strong> featuring a mix of house, techno, and groovy electronic music',
      id: '3XFzRrUYlwY?t=195',
      videoUrl: 'https://www.youtube.com/embed/3XFzRrUYlwY?t=195',
    },
  },
  'rosa-negra-tulum': {
    name: 'Rosa Negra',
    color: '#dd9e6d',
    secondaryColor: '#5b2f27',
    logo:
      'https://imgix.cosmicjs.com/2d020bb0-d4d7-11ee-9ce5-59949019255e-logo-rosa-negra-tulum.png',
    video: {
      title: 'Rosa Negra Tulum',
      youtube: true,
      content:
        'Where <b>Dinner Transforms</b> into a Latin expression with happenings, sparklers, and live music. <b>Join the Vibrant Fiesta!</b></p>',
      id: 'Op0GN4uKEec',
      videoUrl: 'https://www.youtube.com/embed/Op0GN4uKEec',
    },
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=fff2dd,black&duotone-alpha=100`,
  },
  'rosanegra-beach-club-tulum': {
    name: 'Rosanegra Beach Club in Tulum',
    color: '#75abf2',
    logo:
      'https://imgix.cosmicjs.com/ad223160-2004-11ec-99dc-57488d0e52ad-image.png',
    video: {
      title: 'Rosanegra Beach Club in Tulum',
      youtube: true,
      content:
        '<p>Catching a special night event at <b>Rosa Negra Beach Club</b> will be rare. These events have a lit atmosphere, however, they only occur on special occasions</p>',
      id: 'gzrGlo9oA5E',
      videoUrl: 'https://www.youtube.com/embed/gzrGlo9oA5E',
    },
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=75abf2,black&duotone-alpha=100`,
  },
  'gitano-beach-tulum': {
    name: 'Gitano Beach',
    logo:
      'https://imgix.cosmicjs.com/d72577a0-d4da-11ee-9ce5-59949019255e-logo-gitano-beach-tulum.png',
    color: '#ecdbd9',
    secondaryColor: '#ecd1be',
    video: {
      title: 'Gitano Beach',
      content: '<p>Disco caribbean <b>adventures</b> within a perfect beachfront environment. </p>',
      id: 'Gb4JkhHjqk8',
      youtube: 'true',
      videoUrl: 'https://www.youtube.com/embed/Gb4JkhHjqk8',
    },
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=eeaea0,black&duotone-alpha=100`,
  },
  'gitano-tulum': {
    name: 'Gitano Jungle',
    logo:
      'https://imgix.cosmicjs.com/9dca4c20-d4d4-11ee-9ce5-59949019255e-logo-gitano-tulum.png',
    color: '#eeaea0',
    secondaryColor: '#2e3022',
    video: {
      title: 'Gitano Tulum',
      caption: 'JUNGLE FEVER',
      id: 'qrAZXIMNdbw',
      youtube: 'true',
      videoUrl: 'https://www.youtube.com/embed/qrAZXIMNdbw',
    },
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=eeaea0,black&duotone-alpha=100`,
  },
  'taboo-tulum': {
    name: 'Taboo Tulum',
    color: '#dac9c5',
    secondaryColor: '#5f4f4b',
    logo:
      'https://imgix.cosmicjs.com/616c3fe0-d4d9-11ee-9ce5-59949019255e-logo-taboo-tulum.png',
    video: {
      title: 'TABOO',
      content:
        'Taboo Day and Night <br> There are always <a href="/article/taboo-tulum-day-events">party vibes at Taboo Tulum!</a>, At Taboo it doesnt matter whether it is daylight or nighttime, the  music and celebration are always on cue! ',
      id: 'c1RfZMYyFq0',
      youtube: 'true',
      videoUrl: 'https://www.youtube.com/embed/c1RfZMYyFq0',
    },
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=fff2dd,black&duotone-alpha=100&auto=format`,
  },
  'tantra-tulum': {
    name: 'Tantra Tulum',
    color: '#c8b9ac',
    secondaryColor: '#5f4f4b',
    logo:
      'https://imgix.cosmicjs.com/53ed4f20-d5a7-11ee-9ce5-59949019255e-logo-tantra-tulum.png',
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=f86522,black&duotone-alpha=100&auto=format`,
    video: {
      title: 'LUXURIUOS LIVE SHOWS',
      content:
        'Live Sax and violin performances, resident DJs, and a <strong>Luxury festive atmosphere</strong>, is what you can expect at Tantra Tulum.',
      id: 'NwDGVWFgwsc',
      youtube: 'true',
      videoUrl: 'https://www.youtube.com/embed/NwDGVWFgwsc',
    },
    extraImage: '',
  },
  'balabamba-tulum': {
    name: 'Balabamba Tulum',
    color: '#798fdd',
    secondaryColor: '#405bac',
    logo:
      'https://imgix.cosmicjs.com/bd0fae50-d4d8-11ee-9ce5-59949019255e-logo-balabamba-tulum.png',
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=f86522,black&duotone-alpha=100&auto=format`,
    video: {
      title: 'Balabamba Tulum',
      content:'',
      id: '5rzTDhLgPyw',
      youtube: 'true',
      videoUrl: 'https://www.youtube.com/watch?v=5rzTDhLgPyw',
    },
    extraImage: '',
  },
  'chambao-tulum': {
    name: 'Chambao Tulum',
    secondaryColor: '#1f1f1e',
    color: '#c68562',
    logo:
      'https://imgix.cosmicjs.com/f02b1430-195e-11ef-9eca-7d347081a9fb-logo-chambao-tulum.png',
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=f86522,black&duotone-alpha=100&auto=format`,
    video: {
      title: 'Chambao Tulum',
      content:'Fashion Grillhouse. Meets at Chambao Tulum! ✨ Experience the bohemian paradise',
      id: '9il-9ZR0c14',
      youtube: 'true',
      videoUrl: 'https://www.youtube.com/watch?v=9il-9ZR0c14',
    },
  },
  'mayan-monkey-tulum': {
    name: 'Mayan Monkey',
    color: '#93995b',
    // logo:
    //   'https://imgix.cosmicjs.com/a1bbc620-3728-11ec-945e-1fe2e47993be-tantra.png?auto=format',
    // bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=f86522,black&duotone-alpha=100&auto=format`,
    // video: {
    //   title: 'Mayan Monkey',
    //   content:'',
    //   id: '5rzTDhLgPyw',
    //   youtube: 'true',
    //   videoUrl: 'https://www.youtube.com/watch?v=5rzTDhLgPyw',
    // },
  },
  'h-moonlight-tulum': {
    name: 'H MOONLIGHT',
    logo:
      'https://imgix.cosmicjs.com/75e13ad0-cbca-11ec-b98f-db6f075d4374-logo-ilios-r.png',
    color: '#c11e50',
    buttonStyle: {
      color: 'black',
      backgroundColor: '#c11e50',
    },
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=c11e50,black&duotone-alpha=100`,
  },
  'ilios-restaurant-tulum': {
    name: 'ILIOS',
    logo:
      'https://imgix.cosmicjs.com/a6a32060-d5a6-11ee-9ce5-59949019255e-logo-ilios-restaurant-tulum.png',
    color: '#b3a8a3',
    secondaryColor: '#513a1f',
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=bf6c2a,black&duotone-alpha=100`,
    video: {
      title: 'ILIOS Tulum',
      content:'<p>Smash a plate and shout <strong>OPA!</strong></p><p>"Where taste meets passion</p>',
      id: 'Ku4LBQ_QBy4',
      youtube: 'true',
      videoUrl: ' https://www.youtube.com/watch?v=Ku4LBQ_QBy4',
    },

  },
  'karma-restaurant-tulum': {
    name: 'KARMA',
    color: 'rgb(118 92 76)',
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=bf6c2a,black&duotone-alpha=100`,
    logo:
      'https://imgix.cosmicjs.com/a56bd680-da54-11ee-b6bc-0f88b0862d1c-logo-karma-restaurant-tulum.png',
    secondaryColor: 'rgb(40 21 11)',
    video: {
      title: 'Karma Tulum',
      content:'<p>A nested jungle <strong>paradise</strong><br>Unique spaces invite you to experience unforgettable moments, whether enjoying a romantic candlelight dinner or a sunset dj session.</p>',
      id: 'xzs7d_3Ijyk',
      youtube: 'true',
      videoUrl: ' https://www.youtube.com/watch?v=xzs7d_3Ijyk',
    },

  },
  "funky-geisha-tulum": {
    name: 'Funky Geisha Tulum',
    color: '#dd9e6d',
    secondaryColor: '#5b2f27',
    logo:
      'https://imgix.cosmicjs.com/e8632e10-1c9a-11ef-9eca-7d347081a9fb-logo-funky-geisha-tulum.png',
    video: {
      title: 'Funky Geisha',
      content:'<p><b>Asian Inspired</b> Restaurant.<br>🧘 A culinary tantric experience. <br><b>Book Now !</b></p>',
      id: 'g8o6GolbPr4',
      youtube: 'true',
      videoUrl: ' https://www.youtube.com/watch?v=g8o6GolbPr4',
    },

  },
  "meze-tulum": {
    name: 'Meze Tulum',
    color: '#b0a9a6',
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=bf6c2a,black&duotone-alpha=100`,
  },
  "buuts-ha-tulum": {
    logo: 'https://imgix.cosmicjs.com/f491b020-d5a6-11ee-9ce5-59949019255e-logo-buuts-ha-tulum.png',
    name: 'Buuts Ha',
    color: 'rgb(23 97 115)',
    secondaryColor: 'rgb(70 72 71)',
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=bf6c2a,black&duotone-alpha=100`,
  },
  "kanan-beach-club-tulum": {
    name: 'Kanan Beach Club',
    color: '#b0a9a6',
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=bf6c2a,black&duotone-alpha=100`,
  },
});
