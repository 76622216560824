<template>
  <aside>
      <section
        class="col-sm-12"
        :style="{ background: `linear-gradient(${this.primaryColor}, black )` }">
        <h4 class="section-header" >
          <i class="tim-icons icon-trophy" @click="clicked=true"></i>&nbsp; RESOURCES
        </h4>
        <DynamicLinks :links="links"
                      :style="{ background: `linear-gradient(45deg, black, ${this.primaryColor})` }"
        ></DynamicLinks>

      </section>
      <section
        class="col-sm-12"
        :style="{ background: `linear-gradient(${this.primaryColor}, black )` }">
        <img v-lazy="this.djImage"
             height="100px" class="pull-right">
        <h4 class="section-header">
          WHATS HOT?
        </h4>
        <hr style="height: 2px" :style="{ backgroundColor: this.primaryColor }"/>

        <MiniCard
          title="NYE 2025"
          text="HIGH SEASON"
          link="/tulum-new-year-festival-2024"
          image="https://imgix.cosmicjs.com/d38e0f20-9a39-11ee-b34c-99d1d89e42eb-aqua-eye.png"
        ></MiniCard>
        <MiniCard
          title="Zamna Tulum"
          text="The Jungle Is Calling"
          link="/brands/zamna-tulum"
          image="https://imgix.cosmicjs.com/47039b80-cc42-11ed-a5c3-fb6dd2500767-afterlifetulum.jpg"
        ></MiniCard>
      </section>



  </aside>
</template>
<script>
import DynamicLinks from "@/components/DynamicLinks"
import MiniCard from '@/components/Cards/MiniCard'
import RsvpViaDialog from "@/components/Reservations/RsvpViaDialog"

export default {
  name: 'ClubsSmartMenu',
  data: () => {
    return {
      clicked: false,
      djImage:
        'https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png',
      sanDJ: 'https://imgix.cosmicjs.com/f9568ec0-4160-11ef-a504-63e081e4680f-santo-dj.jpg',
      tpLogo: 'https://imgix.cosmicjs.com/07af7270-5002-11ef-b1ea-f56c65dfade9-tulum-party-insta.jpg',
      tulumPartyLogo: 'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png?w=105&h=15&fit=clamp'
    }
  },
  components: {DynamicLinks, MiniCard, RsvpViaDialog},
  props: {
    links: {},
    logo: '',
    primaryColor: {
      type: String,
      default: '#323232'
    },
    topSectionName: {
      type: String,
      default: 'GET IN TOUCH'
    },
    ctaText: {
      type: String,
      default: 'Hit us up!'
    },
  }
}
</script>
