<template>
  <div class="content">
    <article class="row" style="background: #1f1e1f ;">
      <section class="text-center col-xl-8 col-sm-12">
        <img
          v-lazy="clubImage + '?w=350&h=350&fit=clamp&auto=format'"
          :alt="club.title + '_photo'"
          height="350px"
          width="350p"
          style="
            box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);
            max-width: none;
            margin-bottom: 8px;
          "
          class="d-inline-xs d-sm-none"
        />
        <img
          v-lazy="clubImage + '?w=580&h=350&fit=clamp&auto=format'"
          style="
            margin-top: -10px;
            z-index: 12;
            box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);
            border-radius: 0;
          "
          :alt="club.title + '_photo'"
          width="580px"
          class="d-none d-sm-inline"
          height="350px"
        />

        <h1
          class="tulum-party-title text-center"
          style="
            margin-top: 30px;
            text-transform: uppercase;
            letter-spacing: 0.2rem;
          "
        >
          {{ club.title }}
        </h1>


        <div v-if="incomingClubParties.length">

          <img
            v-lazy="this.logo + '?w=40w=40&fit=clamp'"
            height="40px"
            width="40px"
            style="border-radius: 100px; margin-right: -20px; z-index: 2"
          />
          <img
            v-lazy="
              incomingClubParties[0].metadata.main_image.imgix_url +
              '?w=40w=40&fit=clamp'
            "
            :alt="incomingClubParties[0].title"
            height="40px"
            width="40px"
            style="border-radius: 100px;border: 2px solid black"
          />
          <a
            style="margin-left: 5px"
            class="text-left d-inline-lg "
            v-if="incomingClubParties.length"
            href="#incoming-events"
          >Upcoming Events
           ({{ incomingClubParties.length }})
          </a>

        </div>


        <card
          class="card text-center col-md-12 col-md-10 offset-md-1"
          style="z-index: 1; margin: 2px"
        >
          <div class="card">
            <ul class="nav nav-tabs flex-row">
              <li
                role="presentation"
                class="nav-item"
                :class="{ active: this.showDetailsTab }"
              >
                <div class="text-center">
                  <a @click="showTab('details')" class="card-animation-on-hover"
                  >INFO</a
                  >
                </div>
              </li>

              <li
                role="presentation"
                class="nav-item"
                :class="{ active: this.showFaqsTab }"
              >
                <div class="text-center">
                  <a @click="showTab('faqs')" class="card-animation-on-hover"
                  >FAQS</a
                  >
                </div>
              </li>
              <li
                role="presentation"
                class="nav-item"
                :class="{ active: this.showLocationTab }"
              >
                <div class="text-center">
                  <a
                    @click="showTab('location')"
                    class="card-animation-on-hover"
                  >MAP</a
                  >
                </div>
              </li>
            </ul>
            <div id="container" class="tab-content">
              <div
                role="tabpanel"
                class="tab-pane"
                id="details"
                v-if="this.showDetailsTab"
                :class="{ active: this.showDetailsTab }"
              >
                <ClubDetails :config="this.clubTagsConfig" :color="this.clubColor"></ClubDetails>
                <br/>
                <div
                  class="text-center"
                  v-html="club.metadata && club.metadata.description"
                  style="color: #fff !important; margin-bottom: 10px"
                ></div>
                <br/>
              </div>
              <div
                role="tabpanel"
                class="tab-pane"
                id="location"
                v-if="this.showLocationTab"
                :class="{ active: this.showLocationTab }"
              >
                <ClubMapTable :config="this.clubTagsConfig"></ClubMapTable>
              </div>
              <div
                role="tabpanel"
                class="tab-pane"
                id="faqs"
                v-if="this.showFaqsTab"
                :class="{ active: this.showFaqsTab }"
              >
                <ClubFaqs :club-slug="this.slug"></ClubFaqs>
              </div>
              <div class="card"
                   :style="{ background: `linear-gradient(${this.secondaryColor}, black)` }">
                <img
                  v-lazy="astroImg + '?h=30'"
                  height="30px"
                  width="30px"
                  alt="tulum party support"
                  class="d-inline-lg "
                  style="
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    right: 0;
                    margin-top: 20px;
                  "
                />
                <br/>
                <p>Secure your spot at <strong><br>{{ club.title }}</strong></p>
                <br/>

                <RsvpViaDialog
                  text="INFO & RSVP"
                  :club-config="this.rsvpConfig">
                </RsvpViaDialog>
                <br/>
                <small
                >We operate from 9AM - 11PM / Answer within 15 mins</small
                >
                <br>
              </div>
            </div>
          </div>
          <card class="card text-left" style="margin-top: 1px">
            <div
              v-html="club.content"
              class="md-small-hide"
              style="
                color: #fff !important;
                margin-bottom: 1px;
                line-height: 1.4rem;
              "
            ></div>
          </card>


          <div
            v-if="incomingClubParties.length && !incomingClubParties[0].metadata.magicEvent"
            class="row"
            style="padding: 10px"
            :style="{
            background: `linear-gradient(${this.secondaryColor}, black)`,
          }"
          >
            <h4 class="section-header text-left">
              <img
                v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
                height="40px"
                width="40px"
                style="border-radius: 300px"
              />
              Next Event</h4>

            <div class="centered">

              <h3 class="">{{ incomingClubParties[0].title.split("|")[0] }}</h3>
              <EventCard
                :withLineup="false"
                :with-title="false"
                v-bind:event="incomingClubParties[0]"
              >
              </EventCard>
            </div>
          </div>

          <!--        #2a737a-->
          <div
            class="row"
            v-if="incomingClubParties.length"
            style="padding: 10px"
            :style="{ background: `linear-gradient(${clubColor}, black)` }"
            id="incoming-events"
          >
            <h4 class="section-header text-left">
              <img
                v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
                height="40px"
                width="40px"
                style="border-radius: 300px"
              />Upcoming Events ({{ incomingClubParties.length }})
            </h4>

            <br v-if="incomingClubParties.length"/>

            <p style="margin-left: 50px;font-size: 0.6rem;margin-top: -35px"> DINNER, SHOWS & TICKETS
            </p>
            <br v-if="incomingClubParties.length"/>

            <div  v-for="(event, idx) in incomingClubParties.slice(0,  5)"
                  :style="{ background: `linear-gradient(${clubColor}, black)` }"
                 class="card">
              <MiniPartyCard
                large="true"
                v-if="event.metadata.magicEvent"
                v-bind:event="event"
                class="card-animation-on-hover "
                :key="idx"
                :idx="idx"
              ></MiniPartyCard>
              <MiniEventCard
                large="true"
                with-brand="true"
                without-brand="true"
                v-if="!event.metadata.magicEvent"
                v-bind:event="event"
                class="card-animation-on-hover "
                short="true"
                :idx="idx"
                :key="idx"
              ></MiniEventCard>
            </div>

            <div v-for="(event, idx) in incomingClubParties.slice(5,incomingClubParties.length)" class="card" v-if="showAll">
              <MiniPartyCard
                large="true"
                v-if="event.metadata.magicEvent"
                v-bind:event="event"
                class="card-animation-on-hover "
                :key="idx"
                :idx="idx"
              ></MiniPartyCard>
              <MiniEventCard
                large="true"
                with-brand="true"
                without-brand="true"
                :idx="idx"
                v-if="!event.metadata.magicEvent"
                v-bind:event="event"
                class="card-animation-on-hover "
                :key="idx"
              ></MiniEventCard>
            </div>
            <br>


            <div v-if="incomingClubParties.length > 6" class="centered card" style="margin: 20px">
              <div class="text-center centered card" v-if="!showAll">
                <a class="btn-link btn-info text-center" @click="showAll = true" >SHOW ALL
                  (5-{{ incomingClubParties.length }})</a>
              </div>
            </div>

          </div>


          <div id="carrousel" style="margin-top: 30px">
            <h3 class="text-left section-header">
              <img
                v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
                height="40px"
                width="40px"
                style="border-radius: 300px;margin-right: 10px;"
              />
              GALLERY
            </h3>
            <Carrousel :folder="'clubs-'+this.slug" :color="clubColor"></Carrousel>
          </div>

          <div v-if="this.video" style="margin-top: 35px"

          >
            <h3 class="text-left section-header">
              <i class="tim-icons icon-tv-2"></i>&nbsp;ACTION
            </h3>
            <hr
              style="height: 2px; margin-top: -10px"
              :style="{ backgroundColor: this.clubColor }"
            />
            <club-video-album :video="this.video"></club-video-album>
          </div>
          <div>
            <h3
              style="
                margin-bottom: 0;
                color: #ffffff !important;
                font-weight: bold;
              "
            >
              📲 INFO & RSVP
            </h3>
            <br/>
            <p>
              Secure your spot at <strong>{{ club.title }}</strong
            >. Have questions or need more details? We're here to help.
            </p>
             <Reservations :identifier="club.metadata.code"></Reservations>
          </div>
          <br/>
        </card>
        <br/>

        <br/>
        <ClubSearch
          :clubs="this.clubs"
          style="position: fixed; bottom: -10px; z-index: 10"
        ></ClubSearch>
      </section>

      <div class="card col-4 col-xl-4 d-none d-xl-inline">
        <br>
        <h4 class="section-header" style="font-weight: bold">
          <i class="tim-icons icon-chat-33" style="padding: 5px;border-radius: 100px;"
          :style="`{border: '1px solid ${this.secondaryColor}'`"
          ></i> INFO & RSVP
        </h4>


        <div class="row"

        >


          <div class="col-sm-6">
            <p>
              <strong>Have questions or need more details?</strong> We're here
              to help.
            </p>
          </div>
          <div class="col-sm-6 text-center">
            <img v-lazy="astroImg + '?h=30'" height="30px" class="text-center d-none d-xl-inline"
                 style="margin-bottom: 30px"/>
            <br>
            <RsvpViaDialog
              :club-config="this.rsvpConfig"
            text="RSVP"
            ></RsvpViaDialog>
            <small>Secure your spot</small>

          </div>
        </div>
        <br/>
        <br>
        <br>
        <h4 v-if="incomingClubParties.length" class="section-header text-left" style="font-size:0.8rem">
          <img
            v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
            height="40px"
            width="40px"
            style="border-radius: 300px;margin-right: 10px;"
          />Upcoming Events ({{ incomingClubParties.length }})

        </h4>
        <hr
          style="height: 2px"
          :style="{ backgroundColor: clubColor }"
          v-if="incomingClubParties.length"
        />

        <section :style="{ background: `linear-gradient(${this.clubColor}, black )` }">

          <div
            v-for="(event, idx) in incomingClubParties.slice(0, 5)"
               :key="event.idx"
               class="text-left"
          >
            <MiniPartyCard
              v-if="event.metadata.magicEvent"
              :club="clubConfig"
              :event="event"
              large="true"
              :idx="idx"
              class="card-animation-on-hover animation-transition-general animate-bounce"
            ></MiniPartyCard>
            <MiniEventCard
              without-brand="true"
              with-brand="true"
              large="true"
              idx="idx"
              v-if="!event.metadata.magicEvent"
              :event="event"
              class="card-animation-on-hover animation-transition-general animate-bounce"
              :key="idx"
            ></MiniEventCard>
          </div>
          <div v-if="incomingClubParties.length > 6" >
            <div class="text-center centered card" v-if="!showAll">
              <a class="btn-link btn-info text-center" @click="showAll = true" >SHOW ALL
                (5-{{ incomingClubParties.length }})</a>
            </div>
            <div v-for="(event, idx) in incomingClubParties.slice(5,incomingClubParties.length + 1)"
                 :key="event.slug"
                 v-if="showAll"
                 :style="{backgroundColor: clubColor}"
                 class="text-left"
            >
              <MiniPartyCard
                v-if="event.metadata.magicEvent"
                :club="clubConfig"
                large="true"
                :event="event"
                class="card-animation-on-hover animation-transition-general animate-bounce"
                :idx="idx"
              ></MiniPartyCard>
              <MiniEventCard
                with-brand="true"
                large="true"
                without-brand="true"
                v-if="!event.metadata.magicEvent"
                :event="event"
                class="card-animation-on-hover animation-transition-general animate-bounce"
                :idx="idx"
              ></MiniEventCard>
            </div>
          </div>
        </section>


        <ClubsSmartMenu :links="links" :primary-color="clubColor">
        </ClubsSmartMenu>


      </div>
    </article>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Reservations from '../../components/Reservations/Reservations';
import MiniEventCard from '@/components/EventCard/MiniEventCard';
import MiniPartyCard from "@/components/EventCard/MiniPartyCard";
import clubsConfig from '../../common/clubsConfig';
import clubsTableConfig from '../../common/clubsTableConfig';
import ClubSearch from '@/components/Clubs/ClubSearch';
import ClubDetails from '@/components/Clubs/ClubDetailsTable';
import ClubMapTable from '@/components/Clubs/ClubMapTable';
import EventCard from "@/components/EventCard/EventCard";

const RsvpViaDialog = () =>
  import(
    /* webpackChunkName: "RsvpDialogs" */ '../../components/Reservations/RsvpViaDialog'
    );
import ClubVideoAlbum from '@/components/VideoAlbum/ClubVideoAlbum';
import ClubFaqs from '@/components/Clubs/ClubFaqs';
import ClubRecommendedArticles from '@/components/Clubs/ClubRecommenedArticles';
import {slugDecoder} from '@/common/Decoder';
import Carrousel from '@/components/Carrousel';
import ClubsSmartMenu from "@/components/SmartMenu/ClubsSmartMenu";

export default {
  name: 'Club',
  props: ['slug'],
  metaInfo() {
    const ClubName =
      this.club.title || this.slug.replace(/-/g, ' ').toUpperCase();
    const clubDescription = this.club.content || '';
    return {
      title: `${ClubName} · Reservations · Upcoming Events · Tickets`,
      meta: [
        {
          name: 'description',
          content: `${ClubName} · Upcoming Events, Tickets & Reservations. ${clubDescription}`,
        },
        {
          name: 'keywords',
          content: `Tulum, Clubs, Nightclubs, ${this.club.title} party, reservations, bookings, club, nightclub`,
        },
        {
          property: 'og:title',
          content: `${this.club.title} · Reservations · Upcoming Events · Tickets`,
        },
        {
          property: 'og:url',
          content: `https://tulum.party/tulum-clubs/${this.slug}`,
        },
        {
          property: 'og:description',
          content: `${ClubName} · Upcoming Events, Tickets & Reservations.`,
        },
        {property: 'og:type', content: 'article'},
        {
          property: 'og:image',
          content: this.clubImage,
        },
      ],
    };
  },
  data: () => {
    return {
      showAll: false,
      showDetailsTab: true,
      showFaqsTab: false,
      showLocationTab: false,
      showReviewTab: false,
      video: false,
      showPalms: false,
      clubStyle: '',
      clubConfig: {},
      rsvpConfig: {},
      clubTagsConfig: {},
      djImage:
        'https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png',
      astroImg:
        'https://imgix.cosmicjs.com/6ed6b0f0-60cd-11ee-b3cd-9debaa756aee-image.png',
      bgPalm:
        'https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=aquamarine,FA8072&duotone-alpha=100',
      clubColor: 'aquamarine',
    };
  },
  methods: {
    showTab(tab) {
      if (tab === 'location') {
        this.showLocationTab = true;
        this.showDetailsTab = false;
        this.showReviewTab = false;
        this.showFaqsTab = false;
      }
      if (tab === 'details') {
        this.showLocationTab = false;
        this.showReviewTab = false;
        this.showDetailsTab = true;
        this.showFaqsTab = false;
      }
      if (tab === 'faqs') {
        this.showLocationTab = false;
        this.showReviewTab = false;
        this.showDetailsTab = false;
        this.showFaqsTab = true;
      }
    },
  },
  components: {
    ClubsSmartMenu,
    Carrousel,
    ClubRecommendedArticles,
    ClubFaqs,
    MiniPartyCard,
    ClubMapTable,
    ClubDetails,
    EventCard,
    RsvpViaDialog,
    Reservations,
    ClubVideoAlbum,
    ClubSearch,
    MiniEventCard,
  },
  computed:
    mapState({
    incomingClubParties: (state) => {
      return state.incomingClubParties[state.venue.slug] || [];
    },
    club: (state) => state.venue,
    clubs: (state) => state.venues,
    clubImage: (state) =>
      state.venue.metadata ? state.venue.metadata.main_image.imgix_url : '',
    links: (state) => state.links,
  }),
  created() {
    this.clubConfig = clubsConfig[this.slug];
    this.$store.dispatch('getLinks', {slug: 'tulum-clubs'});

    if (!this.clubConfig) {
      this.$rollbar.error('Tulum Clubs - Invalid Slug', this.slug);
      this.$router.push({path: '/tulum-clubs', replace: true});
      return;
    }
    this.$store.dispatch('getVenue', {slug: this.slug});
    this.$store.dispatch('getClubParties', {slug: slugDecoder(this.slug)});
    // this.$store.dispatch('getMiniParties', {slug: slugDecoder(this.slug)});

    this.clubTagsConfig = clubsTableConfig[this.slug];
    this.clubColor = this.clubConfig.color;
    this.secondaryColor = this.clubConfig.secondaryColor;
    this.logo = this.clubConfig.logo;
    this.bgPalm = this.clubConfig.bgPalm;
    this.video = this.clubConfig.video;
    this.rsvpConfig =  {name: this.clubConfig.name, logo:  this.logo + '?w=60&h=60&fit=clamp&auto=format',
      color: this.clubColor, secondaryColor: this.secondaryColor};
  },
};
</script>
